import { useLegalEntityStore } from '@/store/LegalEntityStore'
import { authService } from '@/services/auth.service'
import { legacyUrl } from '@/utils/environment'

export const gotoLegacyApp = (app: string) => {
  if (app === 'pis') {
    console.log('Navigate to pis')
    gotoLegacy('dbp', 365)
  } else {
    console.log(`Navigate to ${app}`)
    gotoLegacy(app, undefined)
  }
}

export const gotoLegacy = (app: string, nav: number | undefined) => {
  const legalEntityStore = useLegalEntityStore()

  let myUrl = legacyUrl + app + '/index.cfm?action=login&domainID=' + legalEntityStore.getSelectedPersonId + '&jwt=' + authService.loadedUser?.access_token
  if (nav) myUrl += '&Nav=' + nav
  console.log(myUrl)
  window.open(myUrl, '_blank')
}

import { defineStore } from 'pinia'
import { LegalEntity } from '@/domain/LegalEntity'
import { personsService, ApplicationUserList } from '@/services/persons'

export const useLegalEntityStore = defineStore('legalEntityStore', {
  persist: true,
  state: () => ({
    applicationUser: null as null | ApplicationUserList,
    legalEntities: [] as LegalEntity[],
    /**
     * @deprecated Diese Variable wird nicht mehr verwendet. Benutze stattdessen `selectedLegalEntity`.
     */
    selectedPersonId: null as number | null, // chose selectedLegalEntityId
    /**
     * @deprecated Diese Variable wird nicht mehr verwendet. Benutze stattdessen `selectedLegalEntityId`.
     */
    selectedPerson: null as null | any,
    selectedLegalEntityId: null as number | null,
    selectedLegalEntity: null as null | any,
    selectedApplicationUserId: null as number | null,
    selectedApplicationUserName: '',
    originLegalEntityId: null as number | null,
    domSwitchLegalEntity: null as null | any,
    loading: false,
    loaders: 0
  }),
  getters: {
    //getSelectedPerson: (state) => state.selectedPerson,
    getSelectedPersonId: (state) => state.selectedLegalEntityId,
    getSelectedLegalEntityId: (state) => state.selectedLegalEntityId,
    getSelectedApplicationUserId: (state) => state.selectedApplicationUserId,
    hasDomainSwitch: (state) => state.selectedLegalEntityId != state.originLegalEntityId,
    getOriginId: (state) => state.originLegalEntityId,
    getOriginLegalEntityId: (state) => state.originLegalEntityId,
    getDomainName: (state) => {
      let legalEntityName = ''
      if (state.selectedLegalEntityId === state.originLegalEntityId) {
        legalEntityName = state.selectedPerson?.name1
        if (state.selectedPerson?.name2) legalEntityName += ' ' + state.selectedPerson?.name2
      } else {
        legalEntityName = state.domSwitchLegalEntity.legalEntityName1
        if (state.domSwitchLegalEntity.legalEntityName2) legalEntityName += ' ' + state.domSwitchLegalEntity.legalEntityName2
      }
      return legalEntityName
    },
    getApplicationUser: (state) => state.applicationUser
  },
  actions: {
    incLoaders() {
      this.loaders++
      this.loading = this.loaders > 0
    },
    decLoaders() {
      this.loaders--
      this.loading = this.loaders > 0
    },
    setApplicationUser(applicationUser: ApplicationUserList) {
      this.applicationUser = applicationUser
    },
    setLegalEntities(le: LegalEntity[]) {
      this.legalEntities = le === undefined ? [] : le.sort((a, b) => ((a.name1 ?? '') > (b.name1 ?? '') ? 1 : (b.name1 ?? '') > (a.name1 ?? '') ? -1 : 0))
    },
    setDomain(newLegalEntityId: number | null) {
      /* only for direct connections to LegalEntity, do not use this for domain-switching */
      this.selectedLegalEntityId = newLegalEntityId
      if (this.originLegalEntityId === null) {
        this.originLegalEntityId = newLegalEntityId
      }
      this.selectedPersonId = this.selectedLegalEntityId
      if (!this.selectedApplicationUserId) {
        // only set originLegalEntityId when the user is not in userswitch mode
        this.originLegalEntityId = newLegalEntityId
      }
      this.selectedLegalEntity = this.legalEntities ? this.legalEntities.filter((pers: LegalEntity) => pers.id == newLegalEntityId)[0] : undefined
      this.selectedPerson = this.selectedLegalEntity
    },
    setSelectedApplicationUser(selectedApplicationUser: ApplicationUserList | undefined) {
      if (selectedApplicationUser === undefined) {
        //restore from domainswitch
        //this.selectedLegalEntityId = 1
        this.selectedApplicationUserName = ''
        this.selectedApplicationUserId = null
        this.selectedPersonId = this.selectedLegalEntityId
        //this.originLegalEntityId = 1
        this.selectedLegalEntity = { ...this.domSwitchLegalEntity }
        this.domSwitchLegalEntity = {}
        this.legalEntities = []
        return
      }
      // test if name is digits only, if yes, display email instead
      this.selectedApplicationUserName =
        !selectedApplicationUser.name || /^\d+$/.test(selectedApplicationUser.name)
          ? (selectedApplicationUser.email ?? '--none--')
          : (selectedApplicationUser.name ?? '--none--')
      this.selectedApplicationUserId = selectedApplicationUser.id
      /* only for direct connections to LegalEntity, do not use this for domain-switching */
      if (selectedApplicationUser.id) {
        // copy to domainswitch
        //this.originLegalEntityId = 1
        this.selectedLegalEntityId = selectedApplicationUser.allowedLegalEntities?.length ? selectedApplicationUser.allowedLegalEntities[0].id : 0
        this.selectedPersonId = this.selectedLegalEntityId
        this.domSwitchLegalEntity = { ...this.selectedLegalEntity }
        this.selectedLegalEntity = {}
        personsService.legalEntities = selectedApplicationUser.allowedLegalEntities
        this.legalEntities = selectedApplicationUser.allowedLegalEntities ?? []
      }
    },
    switchDomain(legalEntity: LegalEntity) {
      this.domSwitchLegalEntity = legalEntity
      this.selectedLegalEntityId = legalEntity.id
      this.selectedPersonId = this.selectedLegalEntityId
    },
    resetDomain() {
      this.selectedLegalEntityId = this.originLegalEntityId
      this.selectedPersonId = this.selectedLegalEntityId
    },
    resetLoaders() {
      this.loaders = 0
    }
  }
})

//import { app } from '@/main'

import { format } from 'date-fns'
import { Plugin } from 'vue'
import { formatChDate, formatChDateTime, formatDateTime } from '@/utils/TimeUtils'

export function formatNumber(number: number, precision: number): string {
  const numberFormatter = new Intl.NumberFormat('de-CH', {
    minimumFractionDigits: precision,
    maximumFractionDigits: precision
  })
  return numberFormatter.format(number)
}

export const Format: Plugin = {
  install(app: any) {
    app.config.globalProperties.$formatNumber = formatNumber

    app.config.globalProperties.$formatChDate = formatChDate

    // Wandelt ISO-Date zu Java localDateTime
    app.config.globalProperties.$formatDateTime = formatDateTime

    // Takes various dates (ISO-Date, epoch, YYYY-MM-DD 00:00:00.123) and converts to chDateTimeFormat
    app.config.globalProperties.$formatChDateTime = formatChDateTime

    // Takes various dates (ISO-Date, epoch, YYYY-MM-DD 00:00:00.123) and converts to chDateFormat
    app.config.globalProperties.$formatDateTimeToChDate = (dateTime: string) => {
      dateTime = app.config.globalProperties.$formatChDateTime(dateTime)
      if (typeof dateTime === 'string' && dateTime.length > 10) {
        dateTime = dateTime.substring(0, 10)
      }
      return dateTime
    }

    app.config.globalProperties.$formatDate = (date: string) => {
      if (!date) return null
      return format(date, 'yyyy-MM-dd')
    }

    app.config.globalProperties.$formatPhone = (phone: string) => {
      if (!phone || typeof phone === 'function') return null
      return phone.replace(/\s+/g, '')
    }
  }
}

import { app, emitter } from '@/main'
import { showError } from './axios'
import { privileges } from '@/services/privileges'
import { Person } from '@/domain/person'
import { useLegalEntityStore } from '@/store/LegalEntityStore'
import { LegalEntity } from '@/domain/LegalEntity'
import type { components } from '@/lib/api/v1.d.ts'
import { authService } from '@/services/auth.service'
import { usePrivilegesStore } from '@/store/privileges'
import { apiURL } from '@/utils/environment'
import { User } from 'oidc-client-ts'

export type ApplicationUserList = components['schemas']['ApplicationUserList']

export class Persons {
  // Its to early in the startup and causes Error: Cannot access 'useLegalEntityStore' before initialization
  // private legalEntityStore = useLegalEntityStore()

  private static _instance: Persons
  public applicationUser: any = {}
  public legalEntities: any = []

  public static get Instance() {
    return this._instance || (this._instance = new this())
  }

  ready: boolean = false

  oidcUserLoaded() {
    const legalEntityStore = useLegalEntityStore()
    personsService
      .checkInUser()
      .then(async (checkInUser) => {
        const legalEntities = personsService.setLegalEntity(checkInUser)
        if (legalEntities) {
          try {
            legalEntityStore.incLoaders()
            await privileges.reset_and_reload()
          } catch (e) {
            console.log(e)
          } finally {
            legalEntityStore.decLoaders()
          }

          return true
        }
      })
      .catch((e) => {
        console.log(e)
        return false
      })
  }
  public async checkInUser() {
    const legalEntityStore = useLegalEntityStore()
    legalEntityStore.resetLoaders()

    try {
      //
      legalEntityStore.incLoaders()
      if (!personsService.applicationUser?.id) {
        const requested = await app.axios.put(apiURL + '/applicationUsers/checkIn/') //set entire obj
        personsService.applicationUser = requested.data as components['schemas']['ApplicationUserList']
        legalEntityStore.setApplicationUser(personsService.applicationUser)
        //set legalentities
        personsService.legalEntities = personsService.applicationUser.allowedLegalEntities
        legalEntityStore.setLegalEntities(personsService.legalEntities)

        await app.axios.patch(apiURL + '/applicationUsers/updateLastLogin')
      }

      //calculate any forceDomain
      this.checkForceDomainSelection()

      //this is null if you don't do a domain switch on any user
      if (personsService.getSelectedApplicationUserId() == null) {
        // has no user-switch
        await app.axios.patch(apiURL + '/applicationUsers/updateLastLogin')
      }
    } catch (e) {
      console.error(e)
      return {}
    } finally {
      legalEntityStore.decLoaders()
    }
    return personsService.applicationUser
  }

  async loadLegalEntity(checkInUser: components['schemas']['ApplicationUserList'], forced: boolean): Promise<LegalEntity[]> {
    const legalEntityStore = useLegalEntityStore()
    if (legalEntityStore.legalEntities.length > 0 && !forced) {
      return legalEntityStore.legalEntities
    }

    try {
      legalEntityStore.incLoaders()
      const response = await app.axios.get(apiURL + '/privileges/legalEntities') // refactor at some point
      const legalEntities: LegalEntity[] = response.data
      legalEntityStore.setLegalEntities(legalEntities)
      personsService.legalEntities = legalEntities
    } finally {
      legalEntityStore.decLoaders()
    }
    return this.setLegalEntity(checkInUser)
  }

  setLegalEntity(checkInUser: components['schemas']['ApplicationUserList']) {
    const legalEntityStore = useLegalEntityStore()

    if (!checkInUser.lastSelectedLegalEntityId) {
      // first time or after a user or domain switch
      console.log('checkin no lastSelectedLegalEntityId')
      legalEntityStore.setDomain(personsService.legalEntities[0].id)
    } else if (personsService.legalEntities.find((p: LegalEntity) => p.id == checkInUser.lastSelectedLegalEntityId)) {
      //unchanged?
      console.log('checkin number validated')
      legalEntityStore.setDomain(checkInUser.lastSelectedLegalEntityId)
    } else {
      // le structure changed lasteSelected not accurate anymore
      console.log('checkin not validated, fallback to first legalEntityId:', personsService.legalEntities[0].id)
      legalEntityStore.setDomain(personsService.legalEntities[0].id)
    }
    this.ready = true
    return personsService.legalEntities
  }

  getSelectedPersonName() {
    const legalEntityStore = useLegalEntityStore()
    if (this.getSelectedApplicationUserId() != null && (this.getSelectedApplicationUserId() ?? 0) > 0) {
      return legalEntityStore.legalEntities[0]?.name1
    } else {
      return ''
    }
  }

  getSelectedApplicationUserId() {
    const legalEntityStore = useLegalEntityStore()
    return legalEntityStore.selectedApplicationUserId
  }

  getSelectedApplicationUserName() {
    const legalEntityStore = useLegalEntityStore()
    return legalEntityStore.selectedApplicationUserName
  }

  /** set/change own domain */
  async setDomain(domainId: number) {
    const legalEntityStore = useLegalEntityStore()
    if (!legalEntityStore.applicationUser) {
      legalEntityStore.applicationUser = { id: 0, lastSelectedLegalEntityId: domainId }
    }
    legalEntityStore.applicationUser.lastSelectedLegalEntityId = domainId
    legalEntityStore.setDomain(domainId)
    try {
      if (!personsService.getSelectedApplicationUserId()) {
        legalEntityStore.incLoaders()
        await app.axios.patch(apiURL + '/applicationUsers/' + personsService.applicationUser.id + '/updateLastSelectedLegalEntity?legalEntityId=' + domainId)
      }
    } catch (e) {
      console.log(e)
    } finally {
      legalEntityStore.decLoaders()
      this.resetStoresPreserveUser()
      await privileges.reset_and_reload()
      if (usePrivilegesStore().privileges.length > 1) {
        emitter.emit('routePushHome')
      }
    }
  }
  /*
*  | components['schemas']['MasterDataList']
      | components['schemas']['MasterDataDairyList']
      | components['schemas']['MasterDataLocalUnitList']
      | components['schemas']['MasterDataFarmList']
      * */
  async impersonateAsUser(selectedApplicationUser: components['schemas']['ApplicationUserList']) {
    console.log('impersonate as user', selectedApplicationUser)
    /*
    if (selectedApplicationUser.applicationUserId) {
      selectedApplicationUser.id = selectedApplicationUser.applicationUserId
      selectedApplicationUser.name = selectedApplicationUser.applicationUserName
      selectedApplicationUser.email = selectedApplicationUser.applicationUserEmail
      selectedApplicationUser.allowedLegalEntities = [
        {
          id: selectedApplicationUser.legalEntityId,
          name1: selectedApplicationUser.legalEntityName1
        }
      ]
    }

     */
    this.resetStoresPreserveUser()
    const legalEntityStore = useLegalEntityStore()

    if (selectedApplicationUser === null) {
      legalEntityStore.setSelectedApplicationUser(undefined)
      legalEntityStore.setDomain(null)
    } else {
      legalEntityStore.setSelectedApplicationUser(selectedApplicationUser)
    }
    await privileges.reset_and_reload()
    this.loadLegalEntity(selectedApplicationUser, true)
    if (usePrivilegesStore().privileges.length > 1) {
      emitter.emit('routePushHome')
    }
  }

  /* switch to another userDomain */
  async switchDomain(legalEntity: LegalEntity) {
    const legalEntityStore = useLegalEntityStore()
    legalEntityStore.switchDomain(legalEntity)
    //legalEntityStore.applicationUser.lastSelectedLegalEntityId = legalEntity.domainId
    this.resetStoresPreserveUser()
    await privileges.reset_and_reload()
    //emitter.emit('reloadNav')
    if (usePrivilegesStore().privileges.length > 1) {
      emitter.emit('routePushHome')
    }
    return true
  }

  async switchDomainAsSelectedUser(legalEntityId: number) {
    const legalEntityStore = useLegalEntityStore()
    legalEntityStore.setDomain(legalEntityId)
    this.resetStoresPreserveUser()
    await privileges.reset_and_reload()
    //emitter.emit('reloadNav')
    emitter.emit('routePushHome')
    return true
  }

  /* switch to another userDomain */
  async backToOriginDomain() {
    const legalEntityStore = useLegalEntityStore()
    legalEntityStore.resetDomain()
    this.resetStoresPreserveUser()

    this.loadLegalEntity(personsService.applicationUser, true)
    //this.setLegalEntity(this.applicationUser)
    await privileges.reset_and_reload()
    //emitter.emit('reloadNav')
    if (usePrivilegesStore().privileges.length > 1) {
      emitter.emit('routePushHome')
    }
    return true
  }

  resetStoresPreserveUser() {
    const somePiniaStore = useLegalEntityStore()
    somePiniaStore.$reset_all_preserve(['legalEntityStore', 'versionStore'])
  }

  reset() {
    this.ready = false
    personsService.applicationUser = {}
    personsService.legalEntities = []
    const legalEntityStore = useLegalEntityStore()
    legalEntityStore.setLegalEntities([])
    legalEntityStore.setDomain(0)

    try {
      delete app.axios.defaults.headers.common['Impersonation']
    } catch (e) {
      showError(e)
    }
  }

  checkForceDomainSelection() {
    const legalEntityStore = useLegalEntityStore()
    if (legalEntityStore.legalEntities.length <= 1) return false // no selection required when only one option
    if (!legalEntityStore.applicationUser?.lastLogin) return false //something went wront abort
    if (legalEntityStore.applicationUser?.lastLogin == null || legalEntityStore.applicationUser.lastSelectedLegalEntityId == null) {
      emitter.emit('forceDomainSelection', true) // no selection required if no last login record
    }
    if (legalEntityStore.applicationUser.lastLogin.substring(0, 10).localeCompare(new Date().toISOString().substring(0, 10)) != 0) {
      emitter.emit('forceDomainSelection', true) // selection required if last login >1day ago
    }
  }
}

export const personsService = Persons.Instance

/* Global Constants */

export const legacyUrl =
  location.hostname === 'prod.dbmilch.ch' || location.hostname === 'prod.bdlait.ch' ? 'https://legacy.dbmilch.ch/' : 'https://test.dbmilch.ch/'
export const protocol: string = process.env.NODE_ENV === 'development' ? 'http://' : 'https://'
export const apiURL: string = process.env.NODE_ENV === 'development' ? '/api' : 'https://' + location.hostname + '/api'
export const isDev: boolean = process.env.NODE_ENV === 'development'

export const itemsPerPageOptions = [10, 20, 50, 100]

export const Languages: string[] = ['de', 'fr', 'it']
export const defaultLanguage = Languages.includes(navigator.language.split('-')[0]) ? navigator.language.split('-')[0] : Languages[0]

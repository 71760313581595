<template>
  <div v-if="loading" id="languageSelector" class="header-bar-right margin-left center-vertically">
    <v-progress-circular indeterminate />
  </div>
  <div v-else class="language-width">
    <v-btn id="lang-activator" class="noborder"> <v-icon dbmpassivgrey> mdi-web </v-icon> {{ selectedLanguage.toUpperCase() }} </v-btn>

    <v-menu activator="#lang-activator">
      <v-list>
        <v-list-item v-for="(item, index) in availableLanguages" :key="index" :value="index">
          <v-list-item-title @click="setSelectedItem(item)">
            {{ item }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { emitter } from '@/main'
import { language } from '@/services/language'
import { showError } from '@/services/axios'
import { Languages } from '@/utils/environment'

export default defineComponent({
  computed: {
    availableLanguages() {
      return Languages.map((lang) => lang.toUpperCase())
    },
    selectedLanguage: {
      get() {
        if (this.$route?.params?.lang) return this.$route?.params?.lang
        return 'de'
      },
      set() {
        //do nothing =
      }
    }
  },
  data() {
    return {
      languageService: language,
      loading: false
    }
  },
  methods: {
    setLanguageLoading(val: boolean) {
      this.loading = val
    },
    setSelectedItem(newValue) {
      const lowerLang = newValue.toLowerCase()
      this.$router
        .push({
          name: this.$route.name,
          params: { ...this.$route.params, lang: lowerLang },
          query: this.$route.query
        })
        .catch(function (e) {
          showError(e)
        })
    }
  },
  mounted() {
    emitter.on('setLanguageLoading', this.setLanguageLoading)
  }
})
</script>

<style lang="scss" scoped>
.language-width {
  width: 60px;
}
</style>

import { language } from '@/services/language'

const defaultLocaleCode: Intl.UnicodeBCP47LocaleIdentifier = 'de-ch'
const acceptedLocaleCodes: readonly Intl.UnicodeBCP47LocaleIdentifier[] = ['de-ch', 'fr-ch', 'it-ch']

/** Returns {@link Intl.Locale} of the user. */
export function getLocale(): Intl.Locale {
  // priority by URL
  const localeCode: Intl.UnicodeBCP47LocaleIdentifier = countryCodeToAcceptedLocales(language.getLang())
  return new Intl.Locale(localeCode)
}

function isAcceptedLocaleCode(localeCode: string): boolean {
  return acceptedLocaleCodes.includes(localeCode.toLowerCase())
}

/**
 * Returns the accepted locale for the given country code or the default locale if the country code is not accepted.
 * @param countryCode
 */
function countryCodeToAcceptedLocales(countryCode: string): (typeof acceptedLocaleCodes)[number] {
  const possibleLocaleCode = countryCode.toLowerCase() + '-ch'
  if (isAcceptedLocaleCode(possibleLocaleCode)) {
    return possibleLocaleCode
  }
  return defaultLocaleCode
}
